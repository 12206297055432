/* GLOBAL */
html, 
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

* {
  box-sizing: border-box;
}
@font-face {
  font-family: Poppins;
  src: url("../assets/fonts/Poppins/Poppins-Regular.ttf");
}
.Poppins {
  font-family: Poppins;
}